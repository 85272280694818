import React, { useEffect, useState } from 'react';
// import './OurService.css';
import service1 from '../images/service1.png';
import service2 from '../images/service2.png';
import service3 from '../images/service3.png';
import service4 from '../images/service4.png';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const OurService = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        dots: false,
        focusOnSelect: true,
        speed: 300,
        centerPadding: '30px',
        infinite: true,
        autoplaySpeed: 5000,
        autoplay: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '120px'
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '65px'
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '55px'
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '45px'
                }
            },
            {
                breakpoint: 330,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '45px'
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    return (
        <div className='our-services' id="our-service">
            <div className='container'>
                <div className='row align-item-center service-head-content'>
                    <div className='col-md-6'>
                        <div className='our-services-head'>
                            <h3 className='title'>{t('our_service.heading')}</h3>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='our-services-head'>
                            <p className='text'>{t('our_service.paragraph')}</p>
                        </div>
                    </div>
                </div>

                <div className='card-sections'>
                    {windowWidth < 576 ? (
                        <div className="row ">
                            <Slider {...settings}>
                                <div className="col-md-3 " >
                                    <div className='card'>
                                        <img src={service1} className="card-img-top" alt="..." />
                                        <div className="card-content">
                                            <h5 className="card-title">{t('our_service.card1.heading')}</h5>
                                            <p className="card-text">{t('our_service.card1.paragraph')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <img src={service2} className="card-img-top" alt="..." />
                                        <div className="card-content">
                                            <h5 className="card-title">{t('our_service.card2.heading')}</h5>
                                            <p className="card-text">{t('our_service.card2.paragraph')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card " >
                                        <img src={service3} className="card-img-top" alt="..." />
                                        <div className="card-content">
                                            <h5 className="card-title">{t('our_service.card3.heading')}</h5>
                                            <p className="card-text">{t('our_service.card3.paragraph')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card " >
                                        <img src={service4} className="card-img-top" alt="..." />
                                        <div className="card-content">
                                            <h5 className="card-title">{t('our_service.card4.heading')}</h5>
                                            <p className="card-text">{t('our_service.card4.paragraph')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>

                    ) : (
                        <div className="row">
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='card'>
                                    <img src={service1} className="card-img-top" alt="..." />
                                    <div className="card-content">
                                        <h5 className="card-title">{t('our_service.card1.heading')}</h5>
                                        <p className="card-text">{t('our_service.card1.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='card'>
                                    <img src={service2} className="card-img-top" alt="..." />
                                    <div className="card-content">
                                        <h5 className="card-title">{t('our_service.card2.heading')}</h5>
                                        <p className="card-text">{t('our_service.card2.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='card'>
                                    <img src={service3} className="card-img-top" alt="..." />
                                    <div className="card-content">
                                        <h5 className="card-title">{t('our_service.card3.heading')}</h5>
                                        <p className="card-text">{t('our_service.card3.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='card'>
                                    <img src={service4} className="card-img-top" alt="..." />
                                    <div className="card-content">
                                        <h5 className="card-title">{t('our_service.card4.heading')}</h5>
                                        <p className="card-text">{t('our_service.card4.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OurService;
