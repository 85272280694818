import React, { useState } from 'react'
// import './Contactus.css';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';


const Contactus = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

    };

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();

        const emailParams = {
            from_name: `${formData.firstName} ${formData.lastName}`,
            to_name: formData.email,
            message: formData.message
        };

        emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPATE_ID,
            emailParams,
            process.env.REACT_APP_PUBLIC_KEY
        ).then((result) => {
            setLoading(false)
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            })

            alert('Email sent successfully!');

        }).catch((error) => {
            setLoading(false)
            console.error('Error sending email:', error);
        });

        // window.Email.send({
        //     Host: process.env.REACT_APP_HOST, // Replace with your SMTP server (e.g., smtp.gmail.com)
        //     Username: process.env.REACT_APP_EMAIL_USER, // Your email address
        //     Password: process.env.REACT_APP_EMAIL_PASS, // Your email password
        //     To: process.env.REACT_APP_EMAIL_USER, // Recipient's email address
        //     From: `${formData.firstName} ${formData.lastName} <${formData.email}>`, // Sender's email
        //     Subject: `New message from ${formData.firstName}`,
        //     Body: formData.message,
        // })
        // .then((message) => {
        //     if (message === 'OK') {
        //         // setSuccessMessage('Email sent successfully!');
        //         setFormData({
        //             firstName: '',
        //             lastName: '',
        //             email: '',
        //             message: ''
        //         });
        //         alert('Email sent successfully!');
        //     } else {
        //         alert('Failed to send email. Please try again later.');
        //         // setErrorMessage('Failed to send email. Please try again later.');
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
            
        //     alert('An error occurred. Please try again later.');

        //     // setErrorMessage('An error occurred. Please try again later.');
        // })
        // .finally(() => {
        //     setLoading(false);
        // });
    };

    return (
        <div className='contact-us' id="contact-us">
            <div className='container' >
                <div className='row res-alignment'>
                <div className='col-md-6'>
                        <div className='contact-us-text' >
                            <h3 className='title'>{t('contact_us.heading')}</h3>
                            <p className='text'>{t('contact_us.paragraph')}</p>
                        </div>
                    </div>
                    <div className='col-md-6 contact-form'>
                        <form onSubmit={handleSubmit} >
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input type="text" placeholder={t('contact_us.firstname')} name="firstName" value={formData.firstName} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input type="text" placeholder={t('contact_us.lastname')} name="lastName" value={formData.lastName}
                                            onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input type="text" placeholder={t('contact_us.email')} name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <textarea placeholder={t('contact_us.message')} name="message"
                                            value={formData.message}
                                            onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='contact-us-button'>
                                        {
                                            loading ? (
                                                <div className='text-light d-flex justify-content-center align-items-center'>
                                                    <div className="spinner-border text-light me-2" role="status">
                                                        <span className="visually-hidden">Loading...</span>

                                                    </div>
                                                    Loading...
                                                </div>

                                            ) : (
                                                <button type="submit">{t('contact_us.button')}</button>

                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <div className='contact-us-form'>

                    <form onSubmit={handleSubmit}>
                        <div className='d-flex'>
                            <div className='contact-us-input me-3' style={{ width: "50%" }}>
                                <input type="text" className='' placeholder={t('contact_us.firstname')} name="firstName" value={formData.firstName} onChange={handleChange} required />
                            </div>
                            <div className='contact-us-input' style={{ width: "50%" }}>
                                <input type="text" className='' placeholder={t('contact_us.lastname')} name="lastName" value={formData.lastName}
                                    onChange={handleChange} required />
                            </div>
                        </div>
                        <div className='contact-us-input mt-3' style={{ width: "100%" }}>
                            <input type="text" className='' placeholder={t('contact_us.email')} name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className='contact-us-textarea mt-3' style={{ width: "100%" }}>
                            <textarea className='' placeholder={t('contact_us.message')} name="message"
                                value={formData.message}
                                onChange={handleChange} required />
                        </div>
                        <div className='contact-us-button mt-3'>
                            {
                                loading ? (
                                    <div className='text-light d-flex justify-content-center align-items-center'>
                                        <div className="spinner-border text-light me-2" role="status">
                                            <span className="visually-hidden">Loading...</span>

                                        </div>
                                        Loading...
                                    </div>

                                ) : (
                                    <button type="submit">{t('contact_us.button')}</button>

                                )
                            }
                        </div>
                    </form>
                </div>
                <div className='contact-us-text' >
                    <h3 className='mx-auto '>{t('contact_us.heading')}</h3>
                    <p className='mx-auto '>{t('contact_us.paragraph')}</p>
                </div> */}
            </div>
        </div>

    )
}

export default Contactus