import React, { useEffect, useState } from 'react'
// import './Aboutus.css';
import aboutLeft from '../images/about_left.png';
import aboutRight1 from '../images/about_right1.png';
import aboutRight2 from '../images/about_right2.png';
import aboutRight3 from '../images/about_right3.png';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import { arrow } from '@popperjs/core';


const Aboutus = () => {
    const { t, i18n } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const imgSlider = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        dots: false,
        speed: 300,
        infinite: true,
        autoplaySpeed: 5000,
        centerPadding: '30px',
        autoplay: true,
        responsive: [

            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '160px'
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '130px'
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '130px'
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '60px'
                }
            },
            {
                breakpoint: 330,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }

        ]
    };


    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [i18n.language]);

    return (
        <div className='about-us' id='about-us'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='about-left'>
                            <div className='about-content'>
                                <h3 className='title'>{t('about_us.heading')}</h3>
                                <p className='text '>{t('about_us.paragraph')}</p>
                            </div>
                            <div className='about-left-img'>
                                <img src={aboutLeft} alt='img' data-aos-duration="2000" data-aos-delay="500" data-aos="fade-up"></img>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        {windowWidth < 768 ? (
                            <div className='img-slider'>
                                <Slider {...imgSlider}>
                                    <div className='slider-img'><img src={aboutLeft} alt='img' /></div>
                                    <div className='slider-img'><img src={aboutRight1} alt='img' /></div>
                                    <div className='slider-img'><img src={aboutRight2} alt='img' /></div>
                                    <div className='slider-img'><img src={aboutRight3} alt='img' /></div>
                                    <div className='slider-img'><img src={aboutLeft} alt='img' /></div>
                                    <div className='slider-img'><img src={aboutRight1} alt='img' /></div>
                                </Slider>
                            </div>
                        ) : (
                            <div className='about-img-content'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='about-right-img'>
                                            <img src={aboutRight1} data-aos-duration="2000" data-aos-delay="500" data-aos="fade-down" alt='img' className='mb-4' />
                                            <img src={aboutRight3} data-aos-duration="2000" data-aos-delay="500" data-aos="fade-up" alt='img' />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='about-right-img'>
                                            <img src={aboutRight2} data-aos={i18n.language === 'ar' ? 'fade-right' : 'fade-left'} data-aos-duration="2000" data-aos-delay="500" alt='img' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Aboutus;