
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import HeroSections from './components/HeroSections';
import Aboutus from './components/Aboutus';
import OurService from './components/OurService';
import Warranty from './components/Warranty';
import Contactus from './components/Contactus';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <HeroSections />
      <Aboutus />
      <OurService />
      <Warranty />
      <Contactus />
      <Footer />
    </>

  );
}

export default App;
