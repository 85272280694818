import React from 'react'
// import "./Footer.css";
import { FaFacebookF, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h6 className='footer-title'>{t('footer.heading')}</h6>
                        <div className='contact-details'>
                            <span className="list-icon"><FaMapMarkerAlt /></span>
                            <p className='list-item'>{t('footer.address')}</p>
                        </div>
                        <div className='contact-details'>
                            <span className="list-icon"><FaPhoneAlt /></span>
                            <Link to='tel:+218916006111' className='list-item text-light'>{t('footer.phone1')}</Link>
                        </div>
                        <div className='contact-details'>
                            <span className="list-icon"><FaPhoneAlt /></span>
                            <Link to='tel:+218512662099' className='list-item text-light'>{t('footer.phone2')}</Link>
                        </div>
                        <div className='contact-details'>
                            <span className="list-icon"><IoMdMail /></span>
                            <Link to='mailto:info@tobacts.ly' className='list-item text-light'>{t('footer.email')}</Link>
                        </div>
                        <div className='social-icon'>
                            <ul>
                                <li><Link to='https://www.facebook.com/toyotaworld.ly' target='_blank'><FaFacebookF /></Link></li>
                                <li><Link to='https://www.instagram.com/toyotaworld.ly/' target='_blank'><FaInstagram /></Link></li>
                                <li><Link to='https://wa.me/+218916006111' target='_blank'><FaWhatsapp /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-6 footer-right'>
                        <h3 className='title'>{t('footer.name')}</h3>
                        <p className='text'>{t('footer.paragraph')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;