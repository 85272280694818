import React from 'react';
import warrantyImg from '../images/warranty-img.png';
import warrantyImg2 from '../images/exclusive-agent.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

const Warranty = () => {
    const { t } = useTranslation();
    const warrantySlider = {
        arrows: false,
        dots: true,
        autoplaySpeed: 5000,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <div className='warranty'>
                <div className='container'>
                    <Slider {...warrantySlider}>
                        <div className='warranty-slider'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='warranty-img'>
                                        <img src={warrantyImg} alt='img' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='warranty-content'>
                                        <h3 className='title'>{t('warranty.card1.heading')}</h3>
                                        <p className='text'>{t('warranty.card1.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='warranty-slider'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='warranty-img'>
                                        <img src={warrantyImg2} alt='img' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='warranty-content'>
                                        <h3 className='title'>{t('warranty.card2.heading')}</h3>
                                        <p className='text'>{t('warranty.card2.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='warranty-slider'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='warranty-img'>
                                        <img src={warrantyImg} alt='img' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='warranty-content'>
                                        <h3 className='title'>{t('warranty.card1.heading')}</h3>
                                        <p className='text'>{t('warranty.card1.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>

                </div>
            </div>
        </>
    );
}
export default Warranty;