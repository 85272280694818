import React, { useEffect, useState } from 'react';
import carImage from '../images/w 1.png';
import logo from '../images/logo.png';
import homeIcon from '../images/Home.svg';
import aboutIcon from '../images/about.svg';
import serviceIcon from '../images/service.svg';
import contactIcon from '../images/contact.svg';
import langIcon from '../images/language.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HeroSections = () => {
    const { t, i18n } = useTranslation();
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const changeLanguage = (lng) => {
    //     i18n.changeLanguage(lng);
    //     document.body.dir = lng === 'ar' ? 'rtl' : 'ltr'; 
    //     localStorage.setItem('language', lng); 
    //     if (lng === 'ar') {
    //         import('./rtl.css'); 
    //     } else{

    //         import('./ltr.css'); 
    //     }
    // };

    useEffect(() => {
        // i18n.changeLanguage("ar");
        // localStorage.setItem('language', "ar");
        // const linkElement = document.getElementById('dynamic-stylesheet');
        // linkElement.href = `/rtl.css`;

        // const handleResize = () => setWindowWidth(window.innerWidth);
        // window.addEventListener('resize', handleResize);
        // return () => window.removeEventListener('resize', handleResize);
    }, [i18n]);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.body.dir = lng === 'ar' ? 'rtl' : 'ltr';
        localStorage.setItem('language', lng);
        const linkElement = document.getElementById('dynamic-stylesheet');
        if (lng === 'ar') {
            linkElement.href = `/rtl.css`;
        } else if (lng === 'en') {
            linkElement.href = `/ltr.css`;
        }


    };
    useEffect(() => {
        const linkElement = document.getElementById('dynamic-stylesheet');

        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
        document.body.dir = savedLanguage === 'ar' ? 'rtl' : 'ltr';
        if (savedLanguage === 'ar') {
            linkElement.href = `/rtl.css`;

        }
        else {
            linkElement.href = `/ltr.css`;

        }
    }, [i18n]);
    const heroContent = [
        {
            id: 1,
            title: t('hero_section.excellence'),
            subtitle: t('hero_section.quality_meets'),
            image: carImage
        },
        {
            id: 2,
            title: t('hero_section.excellence'),
            subtitle: t('hero_section.quality_meets'),
            image: carImage
        },
        {
            id: 3,
            title: t('hero_section.excellence'),
            subtitle: t('hero_section.quality_meets'),
            image: carImage
        }
    ];

    useEffect(() => {
        const bootstrap = require('bootstrap');

        const carouselElement = document.querySelector('#carouselExampleCaptions');
        new bootstrap.Carousel(carouselElement, {
            interval: 3000,
            wrap: true
        });
    }, []);


    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };

    const [activeLink, setActiveLink] = useState('home');

    const handleSetActive = (link) => {
        setActiveLink(link); 
    };

    useEffect(() => {
        const handleScroll = () => {
            const navArea = document.getElementById("navArea");

            if (window.pageYOffset > 0) {
                navArea.classList.add("is-sticky");
            } else {
                navArea.classList.remove("is-sticky");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            <div className="hero">

                <header id="navArea">
                    <nav className="navbar navbar-expand-lg bg-body-tertiary ">
                        <div className="container">
                            <div className="nav-box">
                                <img src={logo} alt="logo" />
                            </div>
                            <button
                                className="navbar-toggler"
                                onClick={openNav}
                                type="button"
                                aria-controls="mySidenav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div id="mySidenav" className="sidenav" style={{ width: isOpen ? '250px' : '' }}>
                                <Link className="closebtn" onClick={closeNav}>
                                    &times;
                                </Link>
                                <ul className="navbar-nav justify-content-end">
                                    <li className="nav-item mx-2">
                                        <a
                                            className={`nav-link ${activeLink === 'home' ? 'active' : ''}`}
                                            aria-current="page"
                                            href="#home"
                                            onClick={() => handleSetActive('home')}
                                        >
                                            <span><img src={homeIcon} alt='icon' /></span>
                                            {t('navbar.home')}
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a
                                            className={`nav-link ${activeLink === 'about' ? 'active' : ''}`}
                                            href="#about-us"
                                            onClick={() => handleSetActive('about')}
                                        >
                                            <span><img src={aboutIcon} alt='icon' /></span>
                                            {t('navbar.about_us')}
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a
                                            className={`nav-link ${activeLink === 'service' ? 'active' : ''}`}
                                            href="#our-service"
                                            onClick={() => handleSetActive('service')}
                                        >
                                            <span><img src={serviceIcon} alt='icon' /></span>
                                            {t('navbar.our_service')}
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a
                                            className={`nav-link ${activeLink === 'contact' ? 'active' : ''}`}
                                            href="#contact-us"
                                            onClick={() => handleSetActive('contact')}
                                        >
                                            <span><img src={contactIcon} alt='icon' /></span>
                                            {t('navbar.contact_us')}
                                        </a>
                                    </li>
                                    <li className="nav-item mx-2 d-flex lang-btn">
                                        <span><img src={langIcon} alt='icon' /></span>
                                        <button 
                                            className={`nav-link btn ${i18n.language === 'en' ? 'active' : ''}`}
                                            onClick={() => {
                                                // console.log('Language changed to EN');
                                                changeLanguage('en');
                                            }}
                                        >
                                            EN
                                        </button>
                                        <span className="text-white mt-2"> | </span>
                                        <button
                                            className={`nav-link btn ${i18n.language === 'ar' ? 'active' : ''}`}
                                            onClick={() => {
                                                // console.log('Language changed to AR');
                                                changeLanguage('ar');
                                            }}
                                        >
                                            AR
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>


                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-indicators">
                        {heroContent.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={index}
                                className={index === 0 ? "active" : ""}
                                aria-current={index === 0 ? "true" : "false"}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                    <div className="carousel-inner">
                        {heroContent.map((content, index) => (
                            <div key={content.id} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                <div className="hero-car">
                                    <div className='container'>
                                        <div className='banner-content'>
                                            <div className="hero-car-text">
                                                <h4>{content.subtitle}</h4>
                                                <h3>{content.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hero-car-img">
                                        <img src={content.image} alt={`Car ${content.id}`} />
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button> */}
                </div>
            </div>

        </>

    );
};

export default HeroSections;
